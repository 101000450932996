import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Layout, Row, Button, Spin, Dropdown } from 'antd';
import './main.css';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import {
    downloadOnHireReportRequest,
} from '../../../core/order/orderActions';
import { getHireRatesRequest } from '../../../core/product/productActions';
import { getOrderRequestCountRequest } from '../../../core/orderRequest/orderRequestActions';
import { getUserRoleAndPermissionsFromCookie } from '../../../core/utils/api';

const Main = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { initialisingDownload } = useSelector(state => state.order);
    const { orderRequestCount } = useSelector(state => state.orderRequest);
    const [showMenuMobile, setShowMenuMobile] = React.useState(false);
    const {
        children,
        className,
        title,
        headerRightColumn,
        afterTitle,
        showMenuSidebar,
        backgroundColor,
    } = props;

    const getUserContext = getUserRoleAndPermissionsFromCookie();

    const menuItems = [
        { position: 1, label: 'Account Dashboard', link: '/account/', pattern: /^\/account\/$/ },
        { position: 2, label: 'Hire Contracts', link: '/account/hire-contracts/', pattern: /^\/account\/hire-contracts\/.*$/ },
        { position: 7, label: 'Addresses', link: '/account/addresses/', pattern: /^\/account\/addresses\/.*$/ },
        { position: 8, label: 'Contacts', link: '/account/contacts/', pattern: /^\/account\/contacts\/.*$/ },
        { position: 9, label: 'Certificates & Instructions', link: '/account/certificates-instructions/', pattern: /^\/account\/certificates-instructions\/.*$/ },
        { position: 10, label: 'Order Templates', link: '/account/saved-lists/', pattern: /^\/account\/saved-lists\/.*$/, reloadDocument: true },
        { position: 11, label: 'Notification Preferences', link: '/account/notifications/', pattern: /^\/account\/notifications\/.*$/ },
        { position: 12, label: 'Account Details', link: '/account/edit/', pattern: /^\/account\/edit\/.*$/, reloadDocument: true },
    ];

    if (getUserContext?.role.toString() === '0') {
        menuItems.push({ position: 4, label: 'Invoices', link: '/account/invoices/', pattern: /^\/account\/invoices\/.*$/ });
        menuItems.push({ position: 5, label: 'Invoice Queries', link: '/account/invoice-queries/', pattern: /^\/account\/invoice-queries\/.*$/ });
        menuItems.push({ position: 6, label: 'Payments', link: '/account/payments/', pattern: /^\/account\/payments\/.*$/ });
        if (getUserContext?.siteUsers > 0) {
            menuItems.push({ position: 3, label: 'Order Requests', count: orderRequestCount, link: '/account/order-requests/', pattern: /^\/account\/order-requests\/.*$/ });
        }
    }

    menuItems.sort((a, b) => a.position - b.position);

    const handleLogout = () => {
        document.querySelector('#logout-anchor').click(); // the logout anchor lives in the header/navbar of the site
    };

    const downloadReport = (value) => {
        dispatch(downloadOnHireReportRequest({ type: value }));
    };

    useEffect(() => {
        if (showMenuSidebar && getUserContext?.role.toString() === '0' && getUserContext?.siteUsers > 0) {
            dispatch(getOrderRequestCountRequest());
        }
    }, []);

    return (
        <Spin
            spinning={initialisingDownload}>
            <Row className="container">
                <Col lg={24} xs={24}>
                    <Row justify={showMenuSidebar ? 'space-between' : 'center'}>
                        <div className="account-sidebar-menu-button" onClick={() => setShowMenuMobile(!showMenuMobile)}>
                            <Row justify="space-between" align="middle">
                                <Col>ACCOUNT</Col>
                                <Col><FontAwesomeIcon icon={faAngleDown} style={{ fontSize: '25px', color: 'white', textAlign: 'center' }} /></Col>
                            </Row>
                        </div>
                        {showMenuSidebar && (
                            <Col lg={5} xs={24}>
                                <div className="account-sidebar">
                                    <div className={`account-sidebar-menu ${showMenuMobile ? 'show-menu-mobile' : ''}`}>
                                        {menuItems.map((item) => (
                                            <Link key={item.label} to={item.link} reloadDocument={item.reloadDocument ?? false}>
                                                <div className={`${location.pathname.match(item.pattern) ? 'account-sidebar-menu-item-active' : ''} account-sidebar-menu-item`}>
                                                    {item.label}
                                                    {'count' in item && (<span style={{ backgroundColor: '#da0812', borderRadius: '50%', padding: '2px 5px', marginLeft: '28px', textAlign: 'center', color: 'white', minWidth: '20px', height: '20px', lineHeight: '14px' }}>{item.count}</span>)}
                                                </div>
                                            </Link>
                                        ))}
                                        <div onClick={handleLogout} className="account-sidebar-menu-item">
                                            <FontAwesomeIcon icon={faArrowRightFromBracket} style={{ color: '#343a40', marginRight: '10px' }} />
                                            Logout
                                        </div>
                                        <Row justify="center">
                                            <Col span={22}>
                                                <div className="account-sidebar-actions">
                                                    <Dropdown
                                                        trigger={['click']}
                                                        placement="bottomLeft"
                                                        menu={{
                                                            items: [
                                                                {
                                                                    key: 'pdf',
                                                                    label: 'PDF',
                                                                    onClick: () => downloadReport('pdf'),
                                                                },
                                                                {
                                                                    key: 'excel',
                                                                    label: 'Excel',
                                                                    onClick: () => downloadReport('excel'),
                                                                },
                                                            ] }}>
                                                        <Button type="primary">
                                                            <Row justify="space-between">
                                                                <Col span={21}>
                                                                    ON-HIRE REPORT
                                                                </Col>
                                                                <Col span={3}>
                                                                    <FontAwesomeIcon icon={faAngleDown} style={{ color: 'white', textAlign: 'center' }} />
                                                                </Col>
                                                            </Row>
                                                        </Button>
                                                    </Dropdown>
                                                    {getUserContext?.role.toString() === '0'
                                                        ? <Button onClick={() => dispatch(getHireRatesRequest())} style={{ whiteSpace: 'normal', height: 'fit-content' }}>DOWNLOAD MY HIRE RATES</Button>
                                                        : null}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        )}
                        <Col lg={18} xs={24}>
                            <div className={`page-wrap ${className}`}>
                                <Layout.Content className="main-content" style={{ backgroundColor }}>
                                    <div className="header">
                                        <Row justify="space-between" align="middle">
                                            <Col span={12}>
                                                {title && <h3 style={{ display: 'inline-block' }}>{title}</h3>}
                                                {afterTitle}
                                            </Col>
                                            <Col span={12}>
                                                {headerRightColumn}
                                            </Col>
                                        </Row>
                                    </div>
                                    {children}
                                </Layout.Content>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Spin>
    );
};

Main.defaultProps = {
    children: false,
    className: '',
    // eslint-disable-next-line react/jsx-no-useless-fragment
    headerRightColumn: <></>,
    title: undefined,
    afterTitle: null,
    showMenuSidebar: true,
    backgroundColor: '#eeeef0',
};

Main.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    title: PropTypes.string,
    headerRightColumn: PropTypes.node,
    afterTitle: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    showMenuSidebar: PropTypes.bool,
    backgroundColor: PropTypes.string,
};

export default Main;
