import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row, Spin } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHelmetSafety } from '@fortawesome/free-solid-svg-icons';

import { getTrackingDataRequest } from '../../core/transport/transportActions';

import Main from '../../components/layout/Main';
import TransportPhotosModal from '../../components/TransportPhotosModal';
import TransportMap from '../../components/TransportMap';
import driverBlank from '../../assets/images/driver-blank.png';
import './style.scss';

const OrderTracking = () => {
    const [photosModalOpen, setPhotosModalOpen] = useState(false);
    const [photoSliderPosition, setPhotoSliderPosition] = useState(0);

    const dispatch = useDispatch();
    const { workItemId } = useParams();
    const { trackingData, isFetchingTrackingData } = useSelector(state => state.transport);

    const showPhotoInModal = (photoIndex) => {
        setPhotoSliderPosition(photoIndex);
        setPhotosModalOpen(true);
    };

    useEffect(() => {
        dispatch(getTrackingDataRequest(workItemId));
    }, []);

    return (
        <Main title="THX STREAM TRACKING" showMenuSidebar={false} backgroundColor="white">
            {!isFetchingTrackingData ? trackingData && (
                <>
                    <div className="order-tracking-container">
                        <div className="order-details">
                            <div className="order-detail-item">
                                <p>{trackingData.type} Date:</p>
                                <p>{trackingData.deliveryDate}</p>
                            </div>
                            <div className="order-detail-item">
                                <p>Status:</p>
                                <p>{trackingData.status}</p>
                            </div>
                            <div className="order-detail-item">
                                <p>PO Number:</p>
                                <p>{trackingData.poNumber}</p>
                            </div>
                            <div className="order-detail-item">
                                <p>THX Order Number:</p>
                                <p>{trackingData.userContractNumber}</p>
                            </div>
                        </div>
                        <div className="site-details-container">
                            <Row justify="space-between">
                                <Col span={12}>
                                    <h2>Site Address</h2>
                                    <address>
                                        <p>{trackingData.address?.line1}</p>
                                        {trackingData.address?.line2 && <p>{trackingData.address?.line2}</p>}
                                        {trackingData.address?.town && <p>{trackingData.address?.town}</p>}
                                        {trackingData.address?.county && <p>{trackingData.address?.county}</p>}
                                        <p>{trackingData.address?.postcode}</p>
                                    </address>
                                </Col>
                                <Col span={2}>
                                    <FontAwesomeIcon icon={faHelmetSafety} style={{ fontSize: '35px', color: '#da0812' }} />
                                </Col>
                            </Row>
                        </div>
                        <div className="status-container">
                            <Row justify="space-between" align="middle">
                                <Col span={6}>
                                    <img src={trackingData.driverPhotoUrl || driverBlank} alt="driver" />
                                </Col>
                                <Col span={17}>
                                    <p>{trackingData.statusMessage}</p>
                                </Col>
                            </Row>
                        </div>
                        {trackingData.status === 'Complete' && (
                            <>
                                <div className="photos-container">
                                    <h2>{trackingData.type} Photos</h2>
                                    <Row gutter={[16, 18]} justify="start" align="middle">
                                        {trackingData.photos?.map((photo, index) => (
                                            <Col span={6} onClick={() => showPhotoInModal(index)}>
                                                <img src={photo} alt="driver" />
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                                <div className="signature-container">
                                    <h2>Signature</h2>
                                    <Row justify="space-between" align="middle">
                                        <Col span={6}>
                                            <img src={trackingData.signatureImageUrl} alt="driver" />
                                        </Col>
                                        <Col span={17}>
                                            <p>Signed by {trackingData.signedBy}</p>
                                        </Col>
                                    </Row>
                                </div>
                            </>
                        )}
                        {trackingData.status === 'Pending' && trackingData.driverLocation && trackingData.siteLocation && (
                            <TransportMap
                                driverCoordinates={{ lat: trackingData.driverLocation.lat, lng: trackingData.driverLocation.lng }}
                                destinationCoordinates={{ lat: trackingData.siteLocation.lat, lng: trackingData.siteLocation.lng }} />
                        )}
                        <a href={`/account/hire-contracts/${trackingData.userContractNumber}`}>
                            <Button style={{ marginTop: '20px' }} type="primary">VIEW FULL ORDER DETAILS</Button>
                        </a>
                    </div>
                    <TransportPhotosModal
                        open={photosModalOpen}
                        setOpen={setPhotosModalOpen}
                        isFetchingPhotos={false}
                        imageUrls={trackingData.photos}
                        setImageUrls={() => {}}
                        position={photoSliderPosition} />
                </>
            ) || (
                <div className="order-tracking-container">
                    <div className="order-details">
                        No tracking details found for {workItemId}
                    </div>
                </div>
            ) : <Spin />}
        </Main>
    );
};

export default OrderTracking;
